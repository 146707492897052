import React, { Component } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import FrontTop from "../layout/FrontTop";
class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Header></Header> 
        <div className="slider-area position-relative">
          <div className="slider-active">
            <div className="single-slider slider-height9 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-10">
                    <div className="hero__caption">
                      <h1 data-animation="fadeInLeft" data-delay="0.4s">
                        About Me
                      </h1>
                    </div>
                  </div>
                </div>
                <section className="about-area2 fix pb-padding pt-50 pb-80">
                  <div className="container">
                    <div className="support-wrapper align-items-center">
                      <div className="right-content2">
                        <div
                          className="right-img wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-delay=".1s"
                        >
                          <img src="assets/img/gallery/about.png" alt="" />
                        </div>
                      </div>
                      <div className="left-content2">
                        <div
                          className="section-tittle2 mb-20 wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-delay=".3s"
                        >
                          <div className="front-text">
                            {/* <h2 className="">About Me</h2> */}
                            <p>
                              You’ll look at graphs and charts in Task One, how
                              to approach the task and the language needed for a
                              successful answer. You’ll examine Task Two
                              questions and learn how to plan, write and check
                              academic essays.
                            </p>
                            <p className="mb-40">
                              Task One, how to approach the task and the
                              language needed for a successful answer. You’ll
                              examine Task Two questions and learn how to plan,
                              write and check academic essays.
                            </p>
                            <a href="courses.html" className="border-btn">
                              My Courses
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>    
    );
  }
}
export default About;
